import { Box, CircularProgress, makeStyles } from '@material-ui/core'
import moment from 'moment'
import React, { FunctionComponentElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Theme } from 'common/theme'

import {
  EntityFilterConfig,
  EntityFilterField,
  EntityFilterOperator,
  EntityFilterType
} from '../../../components/EntityFilters'
import { EntityFilters } from '../../../components/EntityFilters/EntityFilters'
import { useFetchState } from '../../../lib/fetch'
import { getCountryByCurrentUserLang } from '../../../services/country'
import {
  HubEventReviewStatus,
  HubEventReviewTag,
  HubEventType
} from '../../../services/hub-events'
import { CompanyState } from '../../../services/company'

export interface HubEventsFiltersProps {
  onChange: (filters: EntityFilterConfig[]) => void
}

const useStyles = makeStyles<Theme>(() => ({
  hubEventsFilters: {
    //
  }
}))

export function HubEventsFilters({
  onChange
}: HubEventsFiltersProps): FunctionComponentElement<HubEventsFiltersProps> {
  const classes = useStyles()

  const [filters, setFilters] = useState<EntityFilterConfig[]>([])

  const [hubs] = useFetchState(
    `/api/admin/companies/hubs?states=${[
      CompanyState.ACTIVE,
      CompanyState.PAUSED,
      CompanyState.ARCHIVED
    ]}`
  )

  const { t } = useTranslation()

  useEffect(() => {
    onChange(filters)
  }, [filters])

  useEffect(() => {
    if (hubs) {
      const assignedHubOptions = hubs
        .sort((a, b) => a.country.localeCompare(b.country))
        .map((item) => ({
          data: item,
          label: `${item.name} (${item.state})`,
          value: item.uuid
        }))
      const hubEventStatusOptions = [
        { label: 'Approved', value: HubEventReviewStatus.APPROVED },
        { label: 'Auto Approved', value: HubEventReviewStatus.AUTO_APPROVED },
        { label: 'Flagged', value: HubEventReviewStatus.FLAGGED },
        { label: 'Auto Flagged', value: HubEventReviewStatus.AUTO_FLAGGED }
      ]
      const hubEventTypeOptions = [
        { label: 'Registration', value: HubEventType.REGISTRATION },
        { label: 'Sorting', value: HubEventType.SEGREGATION },
        { label: 'Processing', value: HubEventType.PROCESSING },
        { label: 'Clearance', value: HubEventType.CLEARANCE },
        { label: 'Certification', value: HubEventType.CERTIFICATION }
      ]
      const hubEventAnomalies = Object.values<HubEventReviewTag>(
        HubEventReviewTag
      ).map((value) => ({
        value,
        label: t(`HubEventReviewTag.${value}`)
      }))

      setFilters([
        {
          field: EntityFilterField.HUB_EVENT_UUID,
          label: 'Event UUID',
          operator: EntityFilterOperator.EQUALS,
          type: EntityFilterType.TEXT,
          value: ''
        },
        {
          clearable: false,
          field: EntityFilterField.HUB_EVENT_TYPE,
          label: 'Event type',
          operator: EntityFilterOperator.EQUALS,
          options: hubEventTypeOptions,
          type: EntityFilterType.SELECT,
          value: hubEventTypeOptions[0]
        },
        {
          field: EntityFilterField.HUB_EVENT_CREATED_AT,
          label: 'Created at (after)',
          operator: EntityFilterOperator.GREATER_THAN_OR_EQUAL,
          type: EntityFilterType.DATE,
          value: null,
          valueProcessor: (value) =>
            value ? moment(value).startOf('day').toISOString() : null
        },
        {
          field: EntityFilterField.HUB_EVENT_CREATED_AT,
          label: 'Created at (before)',
          operator: EntityFilterOperator.LESS_THAN_OR_EQUAL,
          type: EntityFilterType.DATE,
          value: null,
          valueProcessor: (value) =>
            value ? moment(value).endOf('day').toISOString() : null
        },
        {
          availableOperators: [
            EntityFilterOperator.EQUALS,
            EntityFilterOperator.IS_EMPTY
          ],
          field: EntityFilterField.HUB_EVENT_STATUS,
          label: 'Status',
          operator: EntityFilterOperator.EQUALS,
          options: hubEventStatusOptions,
          type: EntityFilterType.SELECT,
          value: null
        },
        {
          field: EntityFilterField.HUB_EVENT_ANOMALY,
          label: 'Anomaly',
          operator: EntityFilterOperator.EQUALS,
          options: hubEventAnomalies,
          type: EntityFilterType.SELECT,
          value: null
        },
        {
          field: EntityFilterField.HUB_EVENT_HUB_UUID,
          groupBy: (option) =>
            option.data?.country
              ? getCountryByCurrentUserLang(option.data.country)
              : '',
          label: 'Assigned hub',
          multiple: true,
          operator: EntityFilterOperator.IN,
          options: assignedHubOptions,
          type: EntityFilterType.SELECT,
          value: []
        }
      ])
    }
  }, [hubs])

  return (
    <Box className={classes.hubEventsFilters}>
      {filters.length ? (
        <EntityFilters filters={filters} onChange={setFilters} />
      ) : (
        <Box display="flex" justifyContent="center" p={5}>
          <CircularProgress />
        </Box>
      )}
    </Box>
  )
}
